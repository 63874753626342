<template>
  <div class="container-perks">
    <BaseH1 :html="'Zaznacz, <br />co jest skutecznym dla niani'" class="h1" />

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Zalety'" />
      <InputCheckboxGroupChips
        v-model="offer.perks"
        :name="'perks'"
        :options="optionsPerks"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Znajomość języków'" />
      <LanguagesInputCheckboxGroupSticked v-model="offer.languages" />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import InputCheckboxGroupChips from "@/components/UI/Base/InputCheckboxGroupChips.vue";
import LanguagesInputCheckboxGroupSticked from "@/components/LanguagesInputCheckboxGroupSticked.vue";

export default {
  components: {
    BaseH1,
    BaseH2,
    InputCheckboxGroupChips,
    LanguagesInputCheckboxGroupSticked,
  },

  props: {
    perks: Array,
    languages: Array,
  },

  emits: ["update:perks", "update:languages", "update:isValid"],

  setup(props, { emit }) {
    const offer = reactive({
      perks: props.perks,
      languages: props.languages,
    });

    // Way out binding
    watch(
      () => offer.perks,
      (newPerks) => emit("update:perks", newPerks),
      { deep: true }
    );
    watch(
      () => offer.languages,
      (newLanguages) => emit("update:languages", newLanguages),
      { deep: true }
    );

    // Way in binding
    watch(
      () => props.perks,
      (newPerks) => (offer.perks = newPerks),
      { deep: true }
    );
    watch(
      () => props.languages,
      (newLanguages) => (offer.languages = newLanguages),
      { deep: true }
    );

    const isValid = computed(() => {
      return Boolean(offer.languages.length);
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const optionsPerks = [
      {
        label: "posiada prawo jazdy",
        value: "driver_license",
      },
      {
        label: "gotowa do podroży",
        value: "ready_to_travel",
      },
      {
        label: "niepaląca",
        value: "non_smoking",
      },
      {
        label: "posiada własny samochód",
        value: "own_car",
      },
    ];

    return {
      offer,
      isValid,
      optionsPerks,
    };
  },
};
</script>

<style scoped>
.container-perks {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.h1 {
  width: 560px;
}
.wrapper-checkboxes {
  width: 560px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  row-gap: 15px;
}

@media (max-width: 1200px) {
  .container-perks {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .h1 {
    width: 100%;
  }
  .wrapper-checkboxes {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    row-gap: 15px;
  }
}
</style>
