<template>
  <div class="content">
    <OfferNannyInputPerks
      v-model:perks="offerNanny.requirements.perks"
      v-model:languages="offerNanny.requirements.languages"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'OfferNannyCreateResponsibilities' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useOfferNannyStore } from "@/stores/offerNanny";

import OfferNannyInputPerks from "@/components/Offer/Nanny/OfferNannyInputPerks.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    OfferNannyInputPerks,
    ButtonPrimaryNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();

    const isValid = ref(false);

    return {
      offerNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}

@media (max-width: 1200px) {
}
</style>
